import styled from "styled-components";

export const Info = styled.header`
    background : #fff;
    min-height : 600px;
`

export const InfoContainer = styled.div`
max-width: 1500px;
margin-top: 0;
margin : auto;
padding: 0 ;
display: flex;
align-items : center;
justify-content : space-between;

`